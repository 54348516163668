











































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import {DiamondLog, DiamondLogEvent, IpRecord} from '~/components/log/log-data'
import {LogPermission} from '~/utils/permissions'

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Ip Log'
        }
    }
})
export default class DiamondLogTable extends mixins(QueryParserMixin, PaginationMixin) {
    static permission = LogPermission.View

    step: number = 20

    keyword: string = ''
    ip: string = ''

    queryDef = [
        {localVar: 'keyword'},
        {localVar: 'ip'},
    ]

    tableData: IpRecord[] = []

    async updateData() {
        const res = await createRequest('/ip-logs', 'get', {
            skip: (this.currentPage - 1) * this.step,
            limit: this.step,
            keyword: this.keyword,
            ip: this.ip
        }).send()
        this.totalCount = res.data.count
        this.tableData = res.data.ip_logs

        await this.setQuery()
    }

    async created() {
        this.parseQuery()
        await this.setQuery(false)
        await this.updateData()
    }

    ipClicked(ip) {
        this.ip = (this.ip === ip) ? '' : ip
        this.toPage(1)
    }

    memberClicked(mid) {
        this.keyword = (this.keyword === mid) ? '' : mid
        this.toPage(1)
    }
}
